<template>
  <div class="container auth-container">
    <div class="row">
      <div style="position: relative;" class="col-4 left-col left-auth" id="divid">
        <embed :src="tvurl" style="width:300px; height: 100%;">
      </div>

      <div class="right-col text-center">
        <div class="header">
          <a class="header__link">
            <div><img width="200px" height="180px"  class="brandlogo" :src="brandlogourl"></div>
            <div><img width="80px" height="70px" class="wtvlogo" src=".././assets/icons/wtv-icon.svg"></div>
          </a>

          <p class="header__info">
            Log In into Wakaw to see photos and videos from your products.
          </p>
        </div>


          <div class="options" v-if="!otpSent">
            <div class="options__first">
              <button v-if="!isPhoneInputVisible && !phoneSelected"
                class="btn btn-primary btn-block auth-btn"
                @click="openPhoneInput">
                Login With Phone
              </button>
            </div>
            <div class="form-label-group" v-if="isPhoneInputVisible">
              <input
                name="phoneNumber"
                id="phoneNumber"
                class="form-control"
                placeholder="Enter Phone Number"
                v-model="phoneNumber"
              />
            </div>
            <div class="options__first" v-if="phoneSelected">
              <button
                class="btn btn-primary btn-block auth-btn"
                @click="sendOTP">
                Send Code
              </button>
            </div>
            <div style="height:12px"></div>
            <div class="form-label-group" v-if="isEmailInputVisible">
              <input
                name="email"
                id="email"
                class="form-control"
                placeholder="Enter Email"
                v-model="email"
              />
            </div>
            <div class="options__first" v-if="emailSelected">
              <button
                class="btn btn-primary btn-block auth-btn"
                @click="sendOTP">
                Send Code
              </button>
            </div>
            <div class="options__first" v-if="!isEmailInputVisible && !emailSelected">
              <button
                class="btn btn-primary btn-block auth-btn"
                @click="openEmailInput"
              >
                Login With Email
              </button>
            </div>
          </div>
          <div class="form-label-group" v-if="otpSent">
              <input type="password"
                name="otp"
                id="otp"
                class="form-control"
                placeholder="Enter OTP"
                v-model="otp"
              />
          </div>
          <div class="options__first" v-if="otpSent">
              <button
                class="btn btn-primary btn-block auth-btn"
                @click="nextPage()"
              >
                Verify OTP
              </button>
          </div>
      </div>
      <div style="position: relative;padding-left:0px !important;padding-right:0px!important" class="col-4 left-col left-auth" id="divid">
        <embed :src="tvurl" style="width:300px; height: 100%;">
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.use(VueAxios, axios);
//import AgoraRTC from 'agora-rtc-sdk'

export default {
  name: 'Home',
  props: {
  },
  data() {
      return {
        isPhoneInputVisible:false,
        isEmailInputVisible:false,
        phoneNumber:'',
        email:'',
        phoneSelected:false,
        emailSelected:false,
        otpSent:false,
        myloader:'',
        otp:'',
        mode:''

      }
  },
  methods:{
    startLoader() {
			this.myloader = this.$loading.show({ canCancel: false, color:'#34749B' });
		},
		stopLoader() {
			this.myloader.hide();
    },
    sendOTP(){
      if(this.isPhoneInputVisible)
      {
        if(this.phoneNumber == '')
        {
          alert('Mobile Number is mandatory');
          return;
        }
        else if(this.phoneNumber.length!=10)
        {
          alert('Mobile Number is invalid');
          return;
        }
      }
      if(this.isEmailInputVisible)
      {
        if(this.email == '')
        {
          alert('Email is mandatory');
          return;
        }
      }
      console.log(sessionStorage.getItem('apikey'));
      const headers = {
        'apiKey': sessionStorage.getItem('apikey'),
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId')
      }
      if(this.isPhoneInputVisible)
      {
        var phoneNo = "+91" + this.phoneNumber;
        axios.post(`https://api.wakaw.live/v1/otp`,
        {
            phoneNumber:phoneNo,
            type:"sms"
        },{headers: headers})
        .then(response => {
          console.log(response);
          this.mode = "M";
          this.otpSent = true;
          this.isPhoneInputVisible = false;
          this.isEmailInputVisible = false;
          this.emailSelected = false;
          this.phoneSelected = false;
          console.log('hola')
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
      else if(this.isEmailInputVisible)
      {
        var myemail =  this.email;
        axios.post(`https://api.wakaw.live/v1/otp`,
        {
            email:myemail,
            type:"email"
        },{headers: headers})
        .then(response => {
          console.log(response);
          this.otpSent = true;
          this.mode = "E";
          this.isPhoneInputVisible = false;
          this.isEmailInputVisible = false;
          this.emailSelected = false;
          this.phoneSelected = false;
          console.log('holax')
        })
        .catch(e => {
          this.errors.push(e)
        })
      }
    },
    openPhoneInput(){
      this.isPhoneInputVisible = true;
      this.isEmailInputVisible = false;
      this.phoneSelected = true;
      this.emailSelected = false;
    },
    openEmailInput()
    {
      this.isEmailInputVisible = true;
      this.isPhoneInputVisible = false;
      this.emailSelected = true;
      this.phoneSelected = false;
    },
    getBrandID()
    {
      console.log(this.$route.params.brand);
    },
    nextPage()
    {
      const headers = {
        'apiKey': sessionStorage.getItem('apikey'),
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId')
      }
      if(this.mode == "M")
      {
        this.startLoader();
        var phoneNo = "+91" + this.phoneNumber;
        axios.post(`https://api.wakaw.live/v1/authentication/login`,
        {
            phoneNumber:phoneNo,
            otp : ''+this.otp,
            type:"sms",
        },{headers: headers})
        .then(response => {
          console.log(response);
          console.log(response.data.data.accessToken);
          sessionStorage.setItem('accessToken',response.data.data.accessToken);
          sessionStorage.setItem('userId',response.data.data.userId);
          this.stopLoader();
          if(this.$route.params.brand==null || this.$route.params.brand == '')
          {
            console.log('sayonaraa')
            this.$router.push({ path: '/404notfound' });
          }
          else
          {
            if(this.$route.params.videoId != null && this.$route.params.videoId != '')
            {
              if(this.$route.query.uid != null && this.$route.query.uid != '')
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId + '?uid=' + this.$route.query.uid}); 
              }
              else
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId}); 
              }
            }
            else
            {
              this.$router.push({ path: '/Video/'+this.$route.params.brand }); 
            }
          } 
        })
        .catch(e => {
          this.stopLoader();
          console.log('sayonaraa112')
          this.$router.push({ path: '/404notfound' });
          this.errors.push(e)
        })
      }
      else if(this.mode == "E")
      {
        this.startLoader();
        var myemail =  this.email;
        axios.post(`https://api.wakaw.live/v1/authentication/login`,
        {
            email:myemail,
            otp : ''+this.otp,
            type:"email",
        },{headers: headers})
        .then(response => {
          console.log(response);
          console.log(response.data.data.accessToken);
          sessionStorage.setItem('accessToken',response.data.data.accessToken);
          sessionStorage.setItem('userId',response.data.data.userId);
          this.stopLoader();
          if(this.$route.params.brand==null || this.$route.params.brand == '')
          {
            this.$router.push({ path: '/404notfound' });
          }
          else
          {
            if(this.$route.params.videoId != null && this.$route.params.videoId != '')
            {
              if(this.$route.query.uid != null && this.$route.query.uid != '')
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId + '?uid=' + this.$route.query.uid}); 
              }
              else
              {
                this.$router.push({ path: '/Video/'+this.$route.params.brand +'/'+ this.$route.params.videoId}); 
              }
            }
            else
            {
              this.$router.push({ path: '/Video/'+this.$route.params.brand }); 
            }
          }
        })
        .catch(e => {
          this.stopLoader();
          this.$router.push({ path: '/404notfound' });
          this.errors.push(e)
        })
      }
    },
    getConfig()
    {
      //https.globalAgent.options.rejectUnauthorized = false;
      axios.get(`https://api.wakaw.live/v1/config?appVersionNumber=v1.0.0&appType=customer&appVersionName=android`)
      .then(response => {
        console.log(response.data.data.apiKey);
        sessionStorage.setItem('apikey',response.data.data.apiKey);
        this.upsertDevice(response.data.data.apiKey);
      })
      .catch(e => {
        this.errors.push(e)
      })
    },
    upsertDevice(key)
    {
      console.log(key);
      const headers = {
        'apiKey': key,
      }
      axios.post(`https://api.wakaw.live/v1/devices`,
      {
          type: "phone",
          osName: "android",
          osVersion: "10.1",
          deviceIdentifier: "9876548527818ert454512",
          application: {
              applicationType: "customer",
              applicationName: "WakaW Customer App",
              applicationVersionName: "new version1",
              applicationVersionNumber: "0.2",
              applicationKey : "com.wakaw.customer",
              deviceToken: "9ghsj7894522"
          }
      },{headers: headers})
      .then(response => {
        console.log(response);
        sessionStorage.setItem('deviceId',response.data.data.deviceId);
        sessionStorage.setItem('userId',response.data.data.userId);
        sessionStorage.setItem('apikey',response.data.data.apiKey);
      })
      .catch(e => {
        this.errors.push(e)
      })
    }
  },
  computed:{
    brandlogourl:function()
    {
      var url = window.location.origin + '/assets/logo/logo.svg';
      return url;
    },
    tvurl:function()
    {
      var url = window.location.origin + '/TV/';
      url = url + this.$route.params.brand;
      return url;
    }
  },
  created()
  {
    try{
      if(sessionStorage.getItem('accessToken'))
      {
        this.$router.push({ path: '/Video/'+this.$route.params.brand });
      }
      else
      {
        try{
          this.getConfig();
        }
        catch(e)
        {
          console.log(e);
        }
        //this.getBrandID();
      }
    }
    catch(e)
    {
      console.log(e);
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
  window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-4 {
    flex: 0 0 30%;
    max-width: 30%;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px !important;
    padding-left: 15px;
}

.auth-container .right-col {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    width: 400px;
    float: right;
    padding:40px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.text-center {
    text-align: center!important;
}
a {
    color: #F43B55;
    text-decoration: none;
    background-color: transparent;
}
.header__info {
    font-size: 17px;
    line-height: 25px;
    color: #999;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
@media (min-width: 992px)
{
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
}


@media (max-width: 992px)
{
.auth-container .right-col {
    margin: 20px auto;
    margin-top: 0px;
    margin-bottom: 0px;
}
}

.form-control{
    background: #fafafa;
    padding: 8px 0 8px 10px;
    font-size: 14px;
    line-height: 18px;
    min-height: 40px;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-weight: 400;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
}
@media (min-width: 992px)
{
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
}

@media (min-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 1024px;
  }
  
}
@media(max-width: 765px){
  .auth-container .right-col{
      background-color: #fafafa;
    border: none;
    padding-top:5px
}
}
@media (min-width: 576px)
{
  .container, .container-sm {
      max-width: 540px;
  }
}
@media (min-width: 768px)
{
.container {
    max-width: 1024px;
}
}
@media (max-width: 800px)
{
.left-auth {
    display: none;
}
.container{
  padding-right:0px !important;
}

}

.container {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto;
    margin-left: auto;
}
.btn-primary{
  background-color: #F43B55 !important;
    border-color: #F43B55 !important;
}
.btn-primary:hover{
  background-color: #F43B55 !important;
    border-color: #F43B55 !important;
}
.btn-primary:active{
    background-color: #F43B55 !important;
    border-color: #F43B55 !important;
}
.btn-primary:focus{
    background-color: #F43B55 !important;
    border-color: #F43B55 !important;
}
</style>
